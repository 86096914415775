import * as React from 'react';

// @mui
import { Container, Grid, useTheme, Typography } from "@mui/material";

// hooks
import useSettings from '../hooks/useSettings';
// components
import DashboardLayout from '../layouts/dashboard';

import Page from '../components/Page';


import {RegionOverview} from '../sections';
import { usePolkawatchApi } from "../contexts/PolkawatchAPIConext";

// ----------------------------------------------------------------------

export default function GeographyPage() {
    const { themeStretch } = useSettings();

    const { chainMeta } = usePolkawatchApi();


    return (
        <DashboardLayout>
            <Page title={chainMeta.HasStaking ? "Rewards by Geography" : "Decentralization by Geography" }>
                <Container maxWidth={themeStretch ? false : 'xl'}>
                    <RegionOverview/>
                </Container>
            </Page>
        </DashboardLayout>
    );
}
